import React, { Fragment } from "react"
import { GuiaDeEstudoData } from "@/data"
import { Col, Container, Row } from "react-bootstrap"
import Img1 from "@/images/new/guia-de-estudo.png"
import Syllabus from '../assets/documents/2023/Syllabus-2023.pdf'

const GuiaDeEstudo = () => {
  const { sectionContent, button, button2 } = GuiaDeEstudoData;
  return (
    <Fragment>
      <section className="sec-guia-estudo">
        <Container>
          <Row>
            <Col lg={7} md={7} sm={12}>
              <h2 className="title MB_45">{sectionContent.title}</h2>
              <p className="paragraph">{sectionContent.textLine1}</p>
              <p className="paragraph">{sectionContent.textLine2}</p>
              <p className="paragraph">{sectionContent.textLine3}</p>
              <div className="btn-guia">
                <div style={{ marginTop: 72 }}>
                  <a className="blue_btn PD-guia" href={Syllabus}>
                    <span>{button.label}</span>
                  </a>
                </div>
                <div style={{ marginTop: 64, paddingBottom: 124 }}>
                  <a className="blue_btn" href='https://drive.google.com/file/d/1VeZDa9K0OQXLYCUPjHhtHxkSf_bOmXb2/view'>
                    <span>{button2.label}</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12} style={{ display: 'flex', alignItems: 'end' }}>
              <div>
                <img src={Img1} alt="" style={{ width: "100%" }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default GuiaDeEstudo;
