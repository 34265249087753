import React from "react"
import { JornadaSapientiaData } from "@/data"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Etapa1 from "@/images/new/jornadas/1a-etapa.svg"
import Etapa2 from "@/images/new/jornadas/2a-etapa.svg"
import Etapa3 from "@/images/new/jornadas/3a-etapa.svg"
import EtapaGrupo1 from "@/images/new/jornadas/1a-etapa-grupo.svg"
import EtapaGrupo2 from "@/images/new/jornadas/2a-etapa-grupo.svg"
import Correio from "@/images/new/jornadas/correio.png"
import G1 from "@/images/new/jornadas/G1.png"
import Fantastico from "@/images/new/jornadas/fantastico.png"
import Galileu from "@/images/new/jornadas/galileu.png"
import CorreioBraziliense from "@/images/new/jornadas/correioBra.png"
import OPovo from "@/images/new/jornadas/oPovo.png"
import Regulamento from '../assets/documents/2023/Regulamento-Sapientia-2023.pdf'

const JornadaSapientia = () => {
  const { sectionContent } = JornadaSapientiaData;
  return (
    <section className="jornada-sapientia">
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2 className="title-w text-center" style={{ marginBottom: 62 }}>{sectionContent.title}</h2>
            <h5 className="sub-jornada text-center" style={{ marginBottom: 72 }}>{sectionContent.subTitle}</h5>
						<h4 className="sub-title-jornada text-center" style={{ marginBottom: 72 }}>{sectionContent.textTitle}</h4>
          </Col>
        </Row>
        <Row className="fases-individuais">
          <Col lg={4} md={4} sm={12}>
            <div className="text-center MB-72">
              <img src={Etapa1} alt='' />
              <h4>Primeira etapa</h4>
              <p>A jornada Sapientia começa com a aplicação de uma prova objetiva, com foco nos grandes <span>problemas</span> do mundo contemporâneo.</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="text-center MB-72">
              <img src={Etapa2} alt='' />
              <h4>Segunda etapa</h4>
              <p>Uma nova prova objetiva é aplicada, agora, sobre <span>soluções</span> para problemas relevantes do mundo contemporâneo. Poderá conter uma questão discursiva (no formato de uma redação).</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="text-center">
              <img src={Etapa3} alt='' />
              <h4>Terceira etapa</h4>
              <p>Nesta etapa, acontece a apresentação de um pré-projeto, individual, no formato de um texto discursivo, sobre o tema anual da Sapientia.</p>
            </div>
          </Col>
        </Row>
        <div className="line-border" style={{ marginTop: 72 }} />
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h4 className="sub-title-jornada text-center" style={{ margin: "72px 0" }}>{sectionContent.textTitle2}</h4>
          </Col>
        </Row>
        <Row className="fases-individuais" style={{ display: 'flex', justifyContent: 'center' }}>
          <Col lg={4} md={4} sm={12}>
            <div className="text-center MB-72">
              <img src={EtapaGrupo1} alt='' />
              <h4>Quarta etapa</h4>
              <p>Desenvolvimento do projeto. Pode se caracterizar pela criação de um produto, serviço, aplicativo, projeto de lei, startup, ação comunitária, projeto educativo, produção literária ou audiovisual, ou qualquer outro formato.</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} style={{ marginBottom: 122 }}>
            <div className="text-center">
              <img src={EtapaGrupo2} alt='' />
              <h4>Quinta etapa</h4>
              <p>Apresentação de projetos na grande final presencial. As fases em grupo são definidas pelo desenvolvimento de um projeto em PDF de 20 slides contendo toda a apresentação e de um vídeo inspirador de até 3 minutos.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="text-center txt-position">
              <div className="MB-72 MR-58">
                <a href='https://app.olimpiadadofuturo.com.br/' target='_blank' className={`inscreva-se-pink_btn`}>
                  <span>Inscreva-se</span>
                </a>
              </div>
              <div>
                <a href={Regulamento} className={`regulamento3_btn`} target='_blank'>
                  <span>Veja o regulamento</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2 className="title-w text-center" style={{  marginTop: 260, marginBottom: 32 }}>Olimpíada em destaque na imprensa</h2>
            <h5 className="sub-jornada text-center" style={{ marginBottom: 56 }}>Projetos apresentados na Sapientia são destaques nos principais veículos do país.</h5>
          </Col>
        </Row>
        <Row className="text-center">
          <Col lg={2} md={2} sm={12}>
            <div className="text-center MB-72">
              <a href='https://www.correiobraziliense.com.br/app/noticia/eu-estudante/ensino_educacaobasica/2019/05/27/interna-educacaobasica-2019,757907/olimpiada-do-futuro-esta-com-inscricoes-abertas.shtml' target='_blank'>
                <img src={Correio} alt='' />
              </a>
            </div>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="text-center MB-72">
              <a href='https://g1.globo.com/rn/rio-grande-do-norte/noticia/2019/11/19/estudantes-de-mossoro-desenvolvem-canudo-biodegradavel-e-comestivel-e-ganham-medalha-de-ouro-nas-olimpiadas-do-futuro.ghtml' target='_blank' className="text-center MB-72">
                <img src={G1} alt='' />
              </a>
            </div>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="text-center MB-72">
              <a href='https://g1.globo.com/fantastico/noticia/2019/11/17/inspirados-por-yuval-harari-estudantes-criam-projeto-para-combater-plastico.ghtml' target='_blank' className="text-center MB-72">
                <img src={Fantastico} alt='' />
              </a>
            </div>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="text-center MB-72">
              <a href='https://revistagalileu.globo.com/Sociedade/noticia/2019/05/primeira-edicao-de-olimpiada-testa-conhecimentos-de-diferentes-assuntos.html' target='_blank' className="text-center MB-72">
                <img src={Galileu} alt='' />
              </a>
            </div>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="text-center MB-72">
              <a href='https://www.correiobraziliense.com.br/app/noticia/eu-estudante/ensino_educacaobasica/2019/05/27/ensino_educacaobasica_interna,757907/olimpiada-do-futuro-esta-com-inscricoes-abertas.shtml' target='_blank' className="text-center MB-72">
                <img src={CorreioBraziliense} alt='' />
              </a>
            </div>
          </Col>
          <Col lg={2} md={2} sm={12}>
            <div className="text-center MB-72">
              <a href='https://www.opovo.com.br/noticias/2019/11/01/estudantes-cearenses-sao-medalha-de-ouro-na-primeira-edicao-da-olimpiada-sapientia.html' target='_blank' className="text-center">
                <img src={OPovo} alt='' />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="text-center btn-confira-desk" style={{ marginTop: 128 }}>
              <Link to='/imprensa'>
                <div className={`regulamento_btn`}>Confira todas as matérias sobre a Sapientia</div>
              </Link>
            </div>
            <div className="text-center btn-confira-mob" style={{ marginTop: 0 }}>
              <Link to='/imprensa'>
                <div className={`regulamento_2_btn`}>Confira todas as matérias sobre a Sapientia</div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default JornadaSapientia;

