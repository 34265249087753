import React from 'react'
import Carousel from 'nuka-carousel'
import { Col, Container, Row } from "react-bootstrap"
import Bg2 from "@/images/new/banners/finais/Banner-2.png"
import Bg3 from "@/images/new/banners/finais/Banner-3.png"
import Bg4 from "@/images/new/banners/finais/Banner-4.png"
import Bg5 from "@/images/new/banners/finais/Banner-5.png"
import Twitter from "@/images/new/banners/twitter.svg"
import Instagram from "@/images/new/banners/instagram.svg"
import Facebook from "@/images/new/banners/facebook.svg"
import Siganos from "@/images/new/banners/siganos.svg"
import ArrowL from "@/images/arrow-slider-l.svg"
import ArrowR from "@/images/arrow-slider-r.svg"
export default class extends React.Component {
  render() {
    return (
      <section className='slider_section' style={{ paddingBottom: 0 }}>
        <Carousel
          autoplay={false}
          autoplayInterval={8500}
          wrapAround={true}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: "white",
            }
          }}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide} style={{ background: 'transparent', border: 'none' }}><img src={ArrowL} /></button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} style={{ background: 'transparent', border: 'none' }}><img src={ArrowR} /></button>
          )}
        >
          {/* Slider 2 */}
          <div>
            <Container>
              {/* desk */}
              <Row className='d-none-img-desk'>
                <Col lg={5} className="text-left">
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column', marginTop: 200 }}>
                    <h1 className='title-w'>Final da 5ª edição <br />9 de março <br />Foz do Iguaçu</h1>
                    <div style={{ marginTop:56 }}>
                      <a href='final-sapientia-2023' className='common_btn'>ASSISTA AO VIVO</a>
                    </div>
                  </div>
                </Col>
                <Col lg={7}>
                  <div>
                    <div className='slider-icons'>
                      <a href='https://twitter.com/sapientia_of' target='_blank' style={{ marginBottom: 24 }}><img src={Twitter} /></a>
                      <a href='https://www.instagram.com/olimpiadadofuturo/' target='_blank' style={{ marginBottom: 24 }}><img src={Instagram} /></a>
                      <a href='https://www.facebook.com/olimpiadadofuturo/' target='_blank' style={{ marginBottom: 24 }}><img src={Facebook} /></a>
                      <img src={Siganos} />
                    </div>
                    <img src={Bg4}/>
                  </div>
                </Col>
              </Row>
              {/* mob */}
              <Row className='d-none-img-mob'>
                <Col lg={12} className="text-left">
                  <div style={{ height: '100%', marginTop: 123, width: '100%' }}>
                    <img src={Bg4}/>
                  </div>
                </Col>
                <Col lg={12} className="text-left" style={{ background: '#212121' }}>
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <h1 className='title-w' style={{ margin: '32px 0px' }}>Final da 5ª edição <br />9 de março<br />Foz do Iguaçu</h1>
                    <div style={{ marginBottom: 130, display: 'flex', justifyContent: 'center' }}>
                      <a style={{ padding: '16px 68px', color: '#212121' }} href='final-sapientia-2023' className='common_btn'>ASSISTA AO VIVO</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* Slider 3 */}
          <div>
            <Container>
              {/* desk */}
              <Row className='d-none-img-desk'>
                <Col lg={5} className="text-left">
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column', marginTop: 200 }}>
                    <h1 className='title-w'>Confira como foi a final da Sapientia 2022</h1>
                    <div style={{ marginTop:56 }}>
                      <a href='/final-sapientia-2022' className='common_btn'>Ver final 2022</a>
                    </div>
                  </div>
                </Col>
                <Col lg={7}>
                  <div>
                    <div className='slider-icons'>
                      <a href='https://twitter.com/sapientia_of' target='_blank' style={{ marginBottom: 24 }}><img src={Twitter} /></a>
                      <a href='https://www.instagram.com/olimpiadadofuturo/' target='_blank' style={{ marginBottom: 24 }}><img src={Instagram} /></a>
                      <a href='https://www.facebook.com/olimpiadadofuturo/' target='_blank' style={{ marginBottom: 24 }}><img src={Facebook} /></a>
                      <img src={Siganos} />
                    </div>
                    <img src={Bg2}/>
                  </div>
                </Col>
              </Row>
              {/* mob */}
              <Row className='d-none-img-mob'>
                <Col lg={12} className="text-left">
                  <div style={{ height: '100%', marginTop: 123, width: '100%' }}>
                    <img src={Bg2}/>
                  </div>
                </Col>
                <Col lg={12} className="text-left" style={{ background: '#212121' }}>
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <h1 className='title-w' style={{ margin: '32px 0px' }}>Confira como foi a final da Sapientia 2022</h1>
                    <div style={{ marginBottom: 130, display: 'flex', justifyContent: 'center' }}>
                      <a style={{ padding: '16px 68px', color: '#212121' }} href='/final-sapientia-2022' className='common_btn'>Ver final 2022</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* Slider 4 */}
          <div>
            <Container>
              {/* desk */}
              <Row className='d-none-img-desk'>
                <Col lg={5} className="text-left">
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column', marginTop: 200 }}>
                    <h1 className='title-w'>Inicie sua jornada Sapiente Prepare-se para próxima edição</h1>
                    <div style={{ marginTop:56 }}>
                      <a href='/#como-estudar' className='common_btn'>Ver Jornada</a>
                    </div>
                  </div>
                </Col>
                <Col lg={7}>
                  <div>
                    <div className='slider-icons'>
                      <a href='https://twitter.com/sapientia_of' target='_blank' style={{ marginBottom: 24 }}><img src={Twitter} /></a>
                      <a href='https://www.instagram.com/olimpiadadofuturo/' target='_blank' style={{ marginBottom: 24 }}><img src={Instagram} /></a>
                      <a href='https://www.facebook.com/olimpiadadofuturo/' target='_blank' style={{ marginBottom: 24 }}><img src={Facebook} /></a>
                      <img src={Siganos} />
                    </div>
                    <img src={Bg3}/>
                  </div>
                </Col>
              </Row>
              {/* mob */}
              <Row className='d-none-img-mob'>
                <Col lg={12} className="text-left">
                  <div style={{ height: '100%', marginTop: 123, width: '100%' }}>
                    <img src={Bg3}/>
                  </div>
                </Col>
                <Col lg={12} className="text-left" style={{ background: '#212121' }}>
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <h1 className='title-w' style={{ margin: '32px 0px' }}>Inicie sua jornada Sapiente Prepare-se para próxima edição</h1>
                    <div style={{ marginBottom: 130, display: 'flex', justifyContent: 'center' }}>
                      <a style={{ padding: '16px 68px', color: '#212121' }} href='/#como-estudar' className='common_btn'>Ver Jornada</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel>
      </section>
    );
  }
}
