import React from "react"
import { OlimpiadaConectadaData } from "@/data"
import { Col, Container, Row } from "react-bootstrap"
import Img1 from "@/images/new/conectada.png"

const OlimpiadaConectada = () => {
  const { sectionContent } = OlimpiadaConectadaData;
  return (
    <section className="commonSection">
      <Container>
        <Row>
          <Col lg={5} md={5} sm={12}>
						<img className="MB-56" src={Img1} alt="" style={{ width: "100%" }} />
          </Col>
          <Col lg={7} md={7} sm={12}>
            <h2 className="title MB_45">{sectionContent.title}</h2>
            <p className="paragraph">{sectionContent.textLine1}</p>
						<p className="paragraph">{sectionContent.textLine2}</p>
            <h3 className="title" style={{ fontSize: 32 }}>{sectionContent.textLine3}</h3>
						<p className="paragraph">{sectionContent.textLine4}</p>
            <p className="paragraph">{sectionContent.textLine5}</p>
            <div id="modalidade-sapientia" style={{ marginTop: 72 }} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OlimpiadaConectada
