import React, { useState, useEffect, useContext } from "react"
import { LogoImage, NavLinks } from "@/data"
import { Col, Container, Row } from "react-bootstrap"
import { SearchContext } from "@/context/search-context"
import { MenuContext } from "@/context/menu-context"
import { Link } from "gatsby"

const HeaderTwo = () => {
  const [sticky, setSticky] = useState(false);
  const { searchStatus, updateSearchStatus } = useContext(SearchContext);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  
  const handleSearchClick = e => {
    e.preventDefault();
    updateSearchStatus(!searchStatus);
  };
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <header
      className={`header_01 ${
        true === sticky ? "fixedHeader animated flipInX" : null
      }`}
      id="header"
    >
      <Container>
        <Row className="menu-mobile" style={{ flexWrap: 'initial', alignItems: 'center' }}>
          <Col sm={2}>
            <div className="logo" style={{ paddingBottom: '0px' }}>
              <Link to="/">
                <img style={{ width: 'auto' }} src={LogoImage.dark} alt="" title="Sapientia - Olimpíada do Futuro" />
              </Link>
            </div>
          </Col>
          <Col sm={10}>
            <div className="navigator text-right" style={{ paddingBottom: '0px' }}>
              <a
                id="open-overlay-nav"
                className="menu hamburger"
                onClick={handleMenuClick}
                href="#"
              >
                <i className="mei-menu"></i>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between menu-desktop">
          <Col lg={1} md={2} sm={2}>
            <div className="logo">
              <Link to="/">
                <img src={LogoImage.dark} alt="" title="Sapientia - Olimpíada do Futuro" />
              </Link>
            </div>
          </Col>
          <Col lg={11} sm={10} md={10} className="d-none d-lg-block ">
            <nav className="mainmenu">
              <ul style={{ textAlign: 'end' }}>
                {NavLinks.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? "menu-item-has-children"
                          : ""
                      }`}
                    >
                      <Link to={links.url}>{links.name}</Link>
                      {undefined !== links.subItems ? (
                        <ul className="sub-menu">
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
                <a href='https://app.olimpiadadofuturo.com.br/' target='_blank' className="inscreva-se_btn" style={{ padding: '20px 35px' }}>Inscrever-se</a>
                <a href='https://app.olimpiadadofuturo.com.br/' target='_blank' className="login_btn" style={{ padding: '20px 35px' }}>Login</a>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderTwo;
