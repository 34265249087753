import React from "react"
import Layout from "@/components/layout"
import Footer from "@/components/footer"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import Header from "@/components/Header"
import Slider from "@/components/Slider"
import OlimpiadaConectada from "@/components/OlimpiadaConectada"
import GuiaDeEstudo from "@/components/GuiadeEstudo"
import DownloadApps from "@/components/DownloadApps"
import JornadaSapientia from "@/components/JornadaSapientia"
import AjudarAImpulsionar from "@/components/AjudarAImpulsionar"
import ModalidadesSapientia from "@/components/ModalidadesSapientia"
import VideosFinal from "@/components/VideosFinal2021"

const HomeTwo = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home">
          <Header />
          <Slider />
          <OlimpiadaConectada />
          <ModalidadesSapientia />
          <GuiaDeEstudo />
          <DownloadApps />
          <JornadaSapientia />
          <VideosFinal />
          {/* <AjudarAImpulsionar /> */}
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeTwo;
