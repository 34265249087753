import React from "react"
import { Link } from "gatsby"
import AppStore from "@/images/new/appstore.svg"
import Gplay from "@/images/new/gplay.svg"

const DownloadApps = ({}) => {
  return (
    <section className="download-apps">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <h2 className="title-w text-center" style={{ marginBottom: 56 }}>Download dos Apps</h2>
          </div>
        </div>
        <div className="row" id="jornada-sapientia">
          <div className="col-lg-12 col-sm-12 col-md-12 text-center">
            <a href="https://apps.apple.com/br/app/sapientia/id1462287990" target='_blank'>
              <img src={AppStore} alt="" style={{ marginRight: 6 }} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.olympiads" target='_blank'>
              <img src={Gplay} alt="" style={{ marginLeft: 6 }}/>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApps;
