import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import ImgPrincipal from '@/images/new/principal.png'
import ImgAprendizado from '@/images/new/aprendizado.png'

const CallToActionTwo = () => {
  return (
    <Fragment>
      <section className="d-none-img-desk">
        <Container>
          <Row className="text-center">
            <Col lg={12} md={12} sm={12}>
              <h2 className="title" style={{ fontSize: 48, lineHeight: '48px', marginBottom: 24 }}>Onde todos estão convocados para participar</h2>
              <p className="paragraph">Para você participar da Olimpíada, existem duas categorias: uma para competidores oficiais (estudantes) e outra para todos os que desejam aprender sem participar da competição:</p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row style={{ paddingTop: 220 }}>
            <Col lg={6} md={6}>
              <div className={`btn_link  bg_black`} >
                <div className="modalidade-principal text-center" style={{ borderRadius: 32 }}>
                  <div style={{ marginTop: '-40%' }}>
                    <img src={ImgPrincipal} style={{ width: 300 }} />
                  </div>
                  <h3 style={{ marginTop: 24 }}>Categoria Principal</h3>
                  <p>Alunos do 8º ano e do 9° ano do Ensino Fundamental II.</p>
                  <p>Alunos do Ensino Médio, incluindo o 4º ano de cursos técnicos e escolas estrangeiras.</p>
                  <p>Formados no Ensino Médio no ano anterior à edição em questão.</p>
                  <p>Estudantes que estão no 1º ano letivo de qualquer curso do ensino superior.</p>
                  <div style={{ paddingTop: 92, paddingBottom: 40 }}>
                    <a href='https://app.olimpiadadofuturo.com.br/' target='_blank' className={`garantir-vaga_btn`}>
                      <span>Garantir minha vaga</span>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className={`btn_link`} >
                <div className="modalidade-aprendizado text-center" style={{ borderRadius: 32 }}>
                  <div style={{ marginTop: '-40%' }}>
                    <img src={ImgAprendizado} style={{ width: 300 }} />
                  </div>
                  <h3 style={{ marginTop: 24 }}>Categoria Livre</h3>
                  <p>Todos os participantes que não se enquadrem na Categoria Principal, independentemente de ocupação (ex.: estudantes universitários, pais e professores).</p>
                  <p>Atenção: Nesta modalidade os participantes avançam apenas para as fases 1 e 2.</p>
                  <div style={{ paddingTop: 190, paddingBottom: 40 }}>
                    <a href='https://app.olimpiadadofuturo.com.br/' target='_blank' className={`garantir-vaga_btn`}>
                      <span style={{ color: '#212121' }}>Garantir minha vaga</span>
                    </a>
                  </div>
                  <div id="como-estudar" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="noPadding d-none-img-mob" style={{ flexDirection: 'column' }}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2 className="title" style={{ fontSize: 40, lineHeight: '45px', marginBottom: 24 }}>Onde todos estão convocados para participar</h2>
              <p className="paragraph" style={{ marginBottom: 92 }}>Para você participar da Olimpíada, existem duas categorias: uma para competidores oficiais e outra para os que desejam aprender sem participar da competição":</p>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col sm={12} md={12} className="noPadding">
              <div className={`btn_link  bg_black`} >
                <div className="modalidade-principal text-center">
                  <div style={{ marginBottom: 32, marginTop: 32 }}>
                    <img src={ImgPrincipal} />
                  </div>
                  <h3 style={{ marginBottom: 32 }}>Categoria Principal</h3>
                  <p>Alunos do 8º ano e do 9° ano do Ensino Fundamental II.</p>
                  <p>Alunos do Ensino Médio, incluindo o 4º ano de cursos técnicos e escolas estrangeiras.</p>
                  <p>Formados no Ensino Médio no ano anterior à edição em questão.</p>
                  <p style={{ marginBottom: 32 }}>Estudantes que estão no 1º ano letivo de qualquer curso do ensino superior.</p>
                  <div style={{ paddingTop: 32, paddingBottom: 56 }}>
                    <a href='https://app.olimpiadadofuturo.com.br/' target='_blank' className={`garantir-vaga_btn`}>
                      <span>Garantir minha vaga</span>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} className="noPadding">
              <div className={`btn_link`}>
                <div className="modalidade-aprendizado text-center">
                  <div style={{ marginBottom: 32, marginTop: 32 }}>
                    <img src={ImgAprendizado} />
                  </div>
                  <h3 style={{ marginBottom: 32 }}>Categoria Livre</h3>
                  <p>Todos os participantes que não se enquadrem na Categoria Principal,independentemente de ocupação (ex.: estudantes universitários, pais e professores).</p>
                  <p style={{ marginBottom: 32 }}>Atenção: Nesta modalidade os participantes avançam apenas para as fases 1 e 2.</p>
                  <div style={{ paddingTop: 32, paddingBottom: 56 }}>
                    <a href='https://app.olimpiadadofuturo.com.br/' target='_blank' className={`garantir-vaga_btn`}>
                      <span style={{ color: '#212121' }}>Garantir minha vaga</span>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default CallToActionTwo;
