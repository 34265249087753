import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Video1 from "@/images/new/videos/video-1.png"
const TesteVideo = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <img onClick={onOpenModal} src={Video1} style={{ width: '95%' }} />
			<Modal open={open} onClose={onCloseModal} center>
			<iframe src="https://player.vimeo.com/video/794536649?h=31d4b1716b" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen />
			</Modal>
    </div>
  );
};

export default TesteVideo;
