import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Video3 from "@/images/new/videos/video-3.png"
const TesteVideo = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <img onClick={onOpenModal} src={Video3} style={{ width: '95%' }} />
			<Modal open={open} onClose={onCloseModal} center>
            <iframe src="https://player.vimeo.com/video/795272497?h=bfbc0a0ba8" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen />
      </Modal>
    </div>
  );
};

export default TesteVideo;
